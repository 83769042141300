
import * as lightbox from './vendor/simple-lightbox';
import * as general from './components/_general';
import * as burger from './components/_burger';
import * as carousel from './components/_carousel';
import * as contentToggler from './components/_contentToggler';
import * as scrollMenu from './components/_scrollMenu';
import * as menuDropdown from './components/_menuDropdown';
import * as elevator from './components/_elevator';
import * as scrollTo from './components/_scrollTo';
import * as form from './components/_form';
import * as click from './components/_click';
import * as datepicker from './components/_datepicker';



const components = {

	'lightbox': lightbox,
	'general': general,
	'carousel': carousel,
	'contentToggler': contentToggler,
	'scrollMenu': scrollMenu,
	'menuDropdown': menuDropdown,
	'elevator': elevator,
	'burger': burger,
	'scrollTo': scrollTo,
	'form': form,
    'click': click,
    'datepicker': datepicker



};
